import React from "react";

import { graphql } from "gatsby";

import { SadFace } from "components/icons";

const WorkServicesTemplate = (props) => {
  return (
    <>
      <main className="main main--center">
        <h1>
          4<SadFace />4 Error
        </h1>
        <p>Sorry, we can’t find the page you’re looking for.</p>
      </main>
    </>
  );
};

export const query = graphql`
  query err($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      uri
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default WorkServicesTemplate;
